import { useEffect, useMemo, useState } from 'react';
import getDoctorPublicProfile from '../api/getDoctorPublicProfile';
import getCity from '../../../api/getCity';
import getSpeciality from '../../../api/getSpecialties';
import getSecondarySpecialties from '../api/getSecondarySpecialties';
import getLanguagesInProfile from '../api/getLanguagesInProfile';
import getLanguages from '../api/getLanguages';
import getAllExperiences from '../api/getAllExperiences';
import getAllEducations from '../api/getAllEducations';
import getAllMotives from '../api/getAllMotives';
import getAllSchedules from '../api/getAllSchedules';
import getAllAvailabilities from '../api/getAllAvailabilities';
import getProfileApprovalRecord from '../api/getProfileApprovalRecord';

export default function useDoctorUserProfile(selectedUser) {
    const [isLoading, setIsLoading] = useState(true);
    const [basicProfile, setBasicProfile] = useState(null);
    const [cities, setCities] = useState(null);
    const [specialities, setSpecialities] = useState(null);
    const [secondarySpecialties, setSecondarySpecialties] = useState(null);
    const [languages, setLanguages] = useState(null);
    const [experiences, setExperiences] = useState(null);
    const [education, setEducation] = useState(null);
    const [motives, setMotives] = useState(null);
    const [schedules, setSchedules] = useState(null);
    const [availabilities, setAvailabilities] = useState(null);
    const [languagesInProfile, setLanguagesInProfile] = useState(null);
    const [profileApproval, setProfileApproval] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);

    const queryBasicProfile = async () => {
        const getBasicProfileResult = await getDoctorPublicProfile(selectedUser.id, setErrorMessage);

        if (getBasicProfileResult && !getBasicProfileResult.hasProfile) {
            setBasicProfile({});
            return false;
        }

        if (getBasicProfileResult) {
            setBasicProfile(getBasicProfileResult);
            return true;
        }

        setErrorMessage('No se pudo completar la solicitud. Inténtelo de nuevo.');
    };

    const queryCities = async () => {
        const getCities = await getCity(setErrorMessage);

        if (getCities) {
            setCities(getCities);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud. Inténtelo de nuevo.');
    };

    const querySpecialities = async () => {
        const getSpecialities = await getSpeciality(setErrorMessage);

        if (getSpecialities) {
            setSpecialities(getSpecialities);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud. Inténtelo de nuevo.');
    };

    const queryLanguages = async () => {
        const getAvailableLanguages = await getLanguages(setErrorMessage);

        if (getAvailableLanguages) {
            setLanguages(getAvailableLanguages);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de lenguajes. Inténtelo de nuevo.');
    };

    const queryProfileApproval = async () => {
        const getProfileApproval = await getProfileApprovalRecord(selectedUser.id);

        if (getProfileApproval) {
            setProfileApproval(getProfileApproval);
        }
    };

    const queryAllDoctorExperiences = async () => {
        const getAvailableExperiences = await getAllExperiences(selectedUser.id, setErrorMessage);

        if (getAvailableExperiences) {
            setExperiences(getAvailableExperiences);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de experiencias. Inténtelo de nuevo.');
    };

    const queryAllDoctorEducation = async () => {
        const getAvailableEducation = await getAllEducations(selectedUser.id, setErrorMessage);

        if (getAvailableEducation) {
            setEducation(getAvailableEducation);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de educación. Inténtelo de nuevo.');
    };

    const queryAllDoctorMotives = async () => {
        const getAvailableMotives = await getAllMotives(selectedUser.id, setErrorMessage);

        if (getAvailableMotives) {
            setMotives(getAvailableMotives);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de educación. Inténtelo de nuevo.');
    };

    const queryAllDoctorSchedules = async () => {
        const getAvailableSchedules = await getAllSchedules(selectedUser.id, setErrorMessage);

        if (getAvailableSchedules) {
            setSchedules(getAvailableSchedules);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de horarios. Inténtelo de nuevo.');
    };

    const queryAllDoctorAvailabilities = async () => {
        const getAvailableAvailabilities = await getAllAvailabilities(selectedUser.id, setErrorMessage);

        if (getAvailableAvailabilities) {
            setAvailabilities(getAvailableAvailabilities);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de horarios. Inténtelo de nuevo.');
    };

    const querySecondarySpecialities = async () => {
        const getSecondarySpecialities = await getSecondarySpecialties(selectedUser.id, setErrorMessage);
        if (getSecondarySpecialties !== undefined) {
            const formattedSecondary = getSecondarySpecialities.map((secondary) => ({
                id: secondary.idSpecialtyDetail,
                label: secondary.name,
                key: secondary.idSpecialtyDetail
            }));
            setSecondarySpecialties(formattedSecondary);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de especialidades secundarias. Inténtelo de nuevo.');
    };

    const queryLanguagesInProfile = async () => {
        const getLanguages = await getLanguagesInProfile(selectedUser.id, setErrorMessage);

        if (getLanguages) {
            const formattedLanguages = getLanguages.map((language) => ({
                id: language.idLanguageByDoctor,
                label: language.name,
                key: language.idLanguageByDoctor
            }));
            setLanguagesInProfile(formattedLanguages);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de especialidades secundarias. Inténtelo de nuevo.');
    };

    const resetState = () => {
        setBasicProfile(null);
        setCities(null);
        setSecondarySpecialties(null);
        setExperiences(null);
        setAvailabilities(null);
        setLanguagesInProfile(null);
        setErrorMessage(null);
    };

    const formattedSecondarySpecialties = useMemo(() => {
        if (!basicProfile || !specialities || !secondarySpecialties) return;

        return specialities
            .filter((spe) => {
                return (
                    spe.id !== basicProfile.idSpecialty &&
                    secondarySpecialties.findIndex((second) => second.label === spe.name) === -1
                );
            })
            .map((spe) => {
                return {
                    id: spe.id,
                    label: spe.name
                };
            });
    }, [basicProfile, secondarySpecialties, specialities]);

    const formattedLanguages = useMemo(() => {
        if (!basicProfile || !languages || !languagesInProfile) return;

        return languages
            .filter((lang) => {
                return languagesInProfile.findIndex((inProfile) => inProfile.label === lang.name) === -1;
            })
            .map((lang) => {
                return {
                    id: lang.id,
                    label: lang.name
                };
            });
    }, [basicProfile, languages, languagesInProfile]);

    const schedulesAsOptions = useMemo(() => {
        return (
            schedules &&
            schedules
                .filter((item) => item.status)
                .map((item) => ({
                    id: item.id,
                    label: `${item.dayName}: ${item.openHours1} - ${item.openHours2}`
                }))
        );
    }, [schedules]);

    const fetchData = async () => {
        resetState();
        const getBasicProfile = await queryBasicProfile();
        console.log(getBasicProfile, 'getBasicProfile');
        queryCities();
        querySpecialities();

        if (getBasicProfile) {
            console.log('profile apis called');
            querySecondarySpecialities();
            queryLanguages();
            queryLanguagesInProfile();
            queryAllDoctorExperiences();
            queryAllDoctorEducation();
            queryAllDoctorMotives();
            queryAllDoctorSchedules();
            queryAllDoctorAvailabilities();
            queryProfileApproval();
        }

        setIsLoading(false);
    };

    useEffect(() => {

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        basicProfile,
        errorMessage,
        setErrorMessage,
        cities,
        isLoading,
        specialities,
        queryBasicProfile,
        secondarySpecialties,
        formattedSecondarySpecialties,
        querySecondarySpecialities,
        queryLanguagesInProfile,
        languages,
        languagesInProfile,
        formattedLanguages,
        queryAllDoctorExperiences,
        experiences,
        queryAllDoctorEducation,
        education,
        motives,
        queryAllDoctorMotives,
        schedules,
        queryAllDoctorSchedules,
        availabilities,
        queryAllDoctorAvailabilities,
        schedulesAsOptions,
        queryProfileApproval,
        profileApproval,
        fetchData
    };
}
